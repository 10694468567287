
<template>
	<div class="h100 w100 displayFlex flexJCCenter" v-loading="loading">
		<iframe :src="pdfUrl+'#toolbar=0'" frameborder="no" allowtransparency="true" :style="{border: 'none',width: width,height: '100%',backgroundColor: 'transparent'}">
			<p>您的浏览器不支持 iframe 标签,请从列表中下载预览</p>
		</iframe>
	</div>
</template>

<script>
import { getPDF } from '@/api/KYC'
import { reject } from 'q';
export default {
	components: {},
	props: {
		width: {
			type: String,
			default: '100%'
		}
	},
	data () {
		return {
			pdfUrl: '',
			loading: true
		};
	},
	computed: {},
	watch: {},
	methods: {
		// 将返回的流数据转换为url（data: 文档流数据）
		getObjectURL (URL) {
			this.loading = true
			getPDF(URL).then(res => {
				let url = null
				let file = new Blob([res], { type: 'application/pdf;chartset=utf-8' })
				if (window.createObjectURL != undefined) {
					// 通用
					url = window.createObjectURL(file)
				} else if (window.webkitURL != undefined) {
					// 兼容谷歌
					try {
						url = window.webkitURL.createObjectURL(file)
					} catch (error) {

					}
				} else if (window.URL != undefined) {
					// 兼容其他
					try {
						url = window.URL.createObjectURL(file)
					} catch (error) {

					}
				}
				// 将转化后url赋值
				this.pdfUrl = url
				this.loading = false
			}).catch(err => {
				this.loading = false
				reject(err)
			})
		},
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		let index = localStorage.getItem('PDFUrl').indexOf('/api')
		let Url = localStorage.getItem('PDFUrl').slice(index)
		this.getObjectURL(Url)
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {

	},
}
</script>
<style lang='css'>
.el-loading-mask .el-loading-spinner .path {
	stroke: #FF9941;
}
</style>